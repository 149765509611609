// Generated by Framer (552ec80)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-ckx36"

const variantClassNames = {RAV_mcWKy: "framer-v-1p58kd8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "RAV_mcWKy", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 1080, intrinsicWidth: 1080, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1080, pixelWidth: 1080, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/annXDLmWXZMBuDlk2fGLPUMEcw.png", srcSet: "https://framerusercontent.com/images/annXDLmWXZMBuDlk2fGLPUMEcw.png?scale-down-to=512 512w,https://framerusercontent.com/images/annXDLmWXZMBuDlk2fGLPUMEcw.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/annXDLmWXZMBuDlk2fGLPUMEcw.png 1080w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1p58kd8", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RAV_mcWKy"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ckx36.framer-c9udup, .framer-ckx36 .framer-c9udup { display: block; }", ".framer-ckx36.framer-1p58kd8 { height: 53px; overflow: visible; position: relative; width: 53px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framercrrqbv9eA: React.ComponentType<Props> = withCSS(Component, css, "framer-ckx36") as typeof Component;
export default Framercrrqbv9eA;

Framercrrqbv9eA.displayName = "1";

Framercrrqbv9eA.defaultProps = {height: 53, width: 53};

addFonts(Framercrrqbv9eA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})